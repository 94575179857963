import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBSpinner,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardHeader,
    MDBBtn,
    MDBIcon,
    MDBTooltip,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ApiService from 'services/ApiService';
import {
    JobResponseModel,
    JobDetailsSectionModel,
} from 'services/ApiContracts';
import 'styles/JobDetails.css';

// Import necessary modules for React Leaflet
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix the default icon issue with Leaflet in React
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';

import ApplyForJobDialog from 'components/ApplyForJobDialog';
import MarkInterestedDialog from 'components/MarkInterestedDialog';

const DefaultIcon = L.icon({
    iconUrl: markerIconPng,
    shadowUrl: markerShadowPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const apiService = new ApiService();

export interface IJobDetailsPageProps {}

const JobDetailsPage: React.FunctionComponent<IJobDetailsPageProps> = (
    props,
) => {
    const { jobId } = useParams<{ jobId: string }>();
    const [job, setJob] = useState<JobResponseModel | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showApplyModal, setShowApplyModal] = useState<boolean>(false);

    // State variables for Mark Interested functionality
    const [isJobMarked, setIsJobMarked] = useState<boolean>(false);
    const [isSubmittingInterest, setIsSubmittingInterest] =
        useState<boolean>(false);
    const [showMarkInterestedModal, setShowMarkInterestedModal] =
        useState<boolean>(false);

    const navigate = useNavigate();

    const fetchJobDetails = async (id: string) => {
        setLoading(true);
        try {
            const response: JobResponseModel = await apiService.getJobById(
                parseInt(jobId ?? '0', 10),
            );
            setJob(response);

            // Trigger the ViewContent event when the job details are successfully loaded
            if (window.fbq) {
                window.fbq('track', 'ViewContent', {
                    content_name: response.header,
                    content_ids: [jobId],
                    content_type: 'job',
                });
            }
        } catch (error) {
            console.error(
                `Failed to fetch job details for jobId: ${id}`,
                error,
            );
        } finally {
            setLoading(false);
        }
    };

    const navigateJobSearch = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/looking-for-work');
        }
    };

    useEffect(() => {
        if (jobId) {
            fetchJobDetails(jobId);

            // Check if the job is already marked as interested
            const storedJobIds = sessionStorage.getItem(
                'markedInterestedJobIds',
            );
            let jobIds: number[] = storedJobIds ? JSON.parse(storedJobIds) : [];
            setIsJobMarked(jobIds.includes(parseInt(jobId, 10)));
        }
    }, [jobId]);

    // Function to handle Mark Interested button click
    const handleMarkInterested = async () => {
        if (isJobMarked) {
            // Do nothing; the tooltip will handle the message
            return;
        }

        const storedName = sessionStorage.getItem('markInterestedName');
        const storedEmail = sessionStorage.getItem('markInterestedEmail');
        const storedPhone = sessionStorage.getItem('markInterestedPhone');

        if (storedName && storedEmail && storedPhone) {
            // User's contact info is available, submit directly
            setIsSubmittingInterest(true);
            try {
                const markInterestedRequest = {
                    name: storedName,
                    email: storedEmail,
                    phone: storedPhone,
                    jobId: parseInt(jobId ?? '0', 10),
                };

                await apiService.markInterested(markInterestedRequest);

                // Update the job as marked in session storage
                const storedJobIds = sessionStorage.getItem(
                    'markedInterestedJobIds',
                );
                let jobIds: number[] = storedJobIds
                    ? JSON.parse(storedJobIds)
                    : [];

                if (!jobIds.includes(markInterestedRequest.jobId)) {
                    jobIds.push(markInterestedRequest.jobId);
                    sessionStorage.setItem(
                        'markedInterestedJobIds',
                        JSON.stringify(jobIds),
                    );
                }

                setIsJobMarked(true);
            } catch (error) {
                console.error('Failed to mark job as interested:', error);
                // Handle error (e.g., show a message to the user)
            } finally {
                setIsSubmittingInterest(false);
            }
        } else {
            // Open the MarkInterestedDialog to collect user's contact info
            setShowMarkInterestedModal(true);
        }
    };

    // Callback when MarkInterestedDialog is closed after submission
    const handleMarkInterestedDialogSuccess = (submitted: boolean) => {
        setShowMarkInterestedModal(false);
        if (submitted) {
            // Update the job as marked
            setIsJobMarked(true);
        }
    };

    const renderSection = (section: JobDetailsSectionModel) => {
        const entries = Object.entries(section.displayValues);
        const midIndex = Math.ceil(entries.length / 2);
        const firstHalf = entries.slice(0, midIndex);
        const secondHalf = entries.slice(midIndex);

        const renderValueWithNewlines = (value: string) => {
            const lines = value.split('\n');
            return lines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ));
        };

        return (
            <MDBRow key={section.sectionName} className="mt-4">
                <MDBCol size="12">
                    <h5 className="font-weight-bold text-center section-header">
                        {section.sectionName}
                    </h5>
                    <MDBRow>
                        <MDBCol md="6">
                            {firstHalf.map(([label, value]) => (
                                <div key={label} className="text-muted">
                                    <strong>{label}</strong>
                                    <br />
                                    {renderValueWithNewlines(value)}
                                    <br />
                                </div>
                            ))}
                        </MDBCol>
                        <MDBCol md="6">
                            {secondHalf.map(([label, value]) => (
                                <div key={label} className="text-muted">
                                    <strong>{label}</strong>
                                    <br />
                                    {renderValueWithNewlines(value)}
                                    <br />
                                </div>
                            ))}
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        );
    };

    return (
        <MDBContainer fluid className="page-padding">
            {/* ApplyForJobDialog */}
            {jobId && (
                <ApplyForJobDialog
                    showModal={showApplyModal}
                    setShowModal={setShowApplyModal}
                    jobId={parseInt(jobId, 10)}
                />
            )}

            {/* MarkInterestedDialog */}
            {jobId && (
                <MarkInterestedDialog
                    showModal={showMarkInterestedModal}
                    setShowModal={setShowMarkInterestedModal}
                    jobId={parseInt(jobId, 10)}
                    onSuccess={handleMarkInterestedDialogSuccess}
                />
            )}

            <MDBRow className="mb-4">
                <MDBBtn
                    outline
                    color="primary"
                    onClick={navigateJobSearch}
                    style={{ width: 200 }}
                >
                    <MDBIcon className="me-2" icon="arrow-left" />
                    Back to Job Search
                </MDBBtn>
            </MDBRow>
            <MDBRow className="text-center">
                <MDBCol size="12">
                    <h2 className="display-4">Job Details</h2>
                    <hr className="mt-4 centered-hr" />
                </MDBCol>
            </MDBRow>

            {/* Map Display */}
            {!loading &&
                job &&
                job.latitude != null &&
                job.longitude != null && (
                    <MDBRow className="my-4">
                        <MDBCol size="12">
                            <MapContainer
                                center={[job.latitude, job.longitude]}
                                zoom={6}
                                style={{ height: '400px', width: '100%' }}
                                scrollWheelZoom={false}
                                doubleClickZoom={false}
                                touchZoom={false}
                                zoomControl={true}
                                dragging={false}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker
                                    position={[job.latitude, job.longitude]}
                                >
                                    <Popup>{job.header}</Popup>
                                </Marker>
                            </MapContainer>
                        </MDBCol>
                    </MDBRow>
                )}

            {/* Apply and Mark Interested Buttons between map and card */}
            <MDBRow>
                {/* Apply Now Button */}
                <MDBCol xs="12" md="auto" className="mb-2 mb-md-0">
                    <MDBBtn
                        color="secondary"
                        onClick={() => setShowApplyModal(true)}
                    >
                        <MDBIcon fas icon="user-md" className="me-2" />
                        Apply Now
                    </MDBBtn>
                </MDBCol>

                {/* Mark as Interested Button */}
                <MDBCol xs="12" md="auto">
                    {isJobMarked ? (
                        <MDBTooltip
                            tag="span"
                            title="You have already marked your interest in this job!"
                            triggers="click"
                            placement="top"
                        >
                            <MDBBtn
                                color="warning"
                                outline
                                onClick={handleMarkInterested}
                                disabled={isSubmittingInterest}
                            >
                                {isSubmittingInterest ? (
                                    <>
                                        <MDBSpinner
                                            size="sm"
                                            role="status"
                                            className="me-2"
                                        />
                                        Marked as Interested
                                    </>
                                ) : (
                                    <>
                                        <MDBIcon
                                            fas
                                            icon="star"
                                            className="me-2"
                                        />
                                        Marked as Interested
                                    </>
                                )}
                            </MDBBtn>
                        </MDBTooltip>
                    ) : (
                        <MDBBtn
                            color="warning"
                            outline
                            onClick={handleMarkInterested}
                            disabled={isSubmittingInterest}
                        >
                            {isSubmittingInterest ? (
                                <>
                                    <MDBSpinner
                                        size="sm"
                                        role="status"
                                        className="me-2"
                                    />
                                    Mark as Interested
                                </>
                            ) : (
                                <>
                                    <MDBIcon
                                        fas
                                        iconType="regular"
                                        icon="star"
                                        className="me-2"
                                    />
                                    Mark as Interested
                                </>
                            )}
                        </MDBBtn>
                    )}
                </MDBCol>
            </MDBRow>

            <MDBRow className="my-4 text-center">
                {loading ? (
                    <MDBContainer className="text-center">
                        <MDBSpinner size="lg" color="primary" />
                    </MDBContainer>
                ) : !job ? (
                    <p>Job details not found.</p>
                ) : (
                    <MDBCard>
                        {job.imageUrl && (
                            <MDBCardImage
                                position="top"
                                src={job.imageUrl}
                                alt="healthcare facility"
                                style={{
                                    maxWidth: 640,
                                    display: 'block',
                                    margin: '0 auto',
                                }}
                            />
                        )}
                        <MDBCardHeader className="bg-primary text-white">
                            <h5 className="mb-0">{job.header}</h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                            {job.displaySections.map(renderSection)}
                            {/* Apply and Mark Interested Buttons at the bottom of the card content */}
                            <MDBRow className="mt-4">
                                {/* Apply Now Button */}
                                <MDBCol
                                    xs="12"
                                    md="auto"
                                    className="mb-2 mb-md-0"
                                >
                                    <MDBBtn
                                        color="secondary"
                                        onClick={() => setShowApplyModal(true)}
                                    >
                                        <MDBIcon
                                            fas
                                            icon="user-md"
                                            className="me-2"
                                        />
                                        Apply Now
                                    </MDBBtn>
                                </MDBCol>

                                {/* Mark as Interested Button */}
                                <MDBCol xs="12" md="auto">
                                    {isJobMarked ? (
                                        <MDBTooltip
                                            tag="span"
                                            title="You have already marked your interest in this job!"
                                            triggers="click"
                                            placement="top"
                                        >
                                            <MDBBtn
                                                color="warning"
                                                outline
                                                onClick={handleMarkInterested}
                                                disabled={isSubmittingInterest}
                                            >
                                                {isSubmittingInterest ? (
                                                    <>
                                                        <MDBSpinner
                                                            size="sm"
                                                            role="status"
                                                            className="me-2"
                                                        />
                                                        Marked as Interested
                                                    </>
                                                ) : (
                                                    <>
                                                        <MDBIcon
                                                            fas
                                                            icon="star"
                                                            className="me-2"
                                                        />
                                                        Marked as Interested
                                                    </>
                                                )}
                                            </MDBBtn>
                                        </MDBTooltip>
                                    ) : (
                                        <MDBBtn
                                            color="warning"
                                            outline
                                            onClick={handleMarkInterested}
                                            disabled={isSubmittingInterest}
                                        >
                                            {isSubmittingInterest ? (
                                                <>
                                                    <MDBSpinner
                                                        size="sm"
                                                        role="status"
                                                        className="me-2"
                                                    />
                                                    Mark as Interested
                                                </>
                                            ) : (
                                                <>
                                                    <MDBIcon
                                                        fas
                                                        iconType="regular"
                                                        icon="star"
                                                        className="me-2"
                                                    />
                                                    Mark as Interested
                                                </>
                                            )}
                                        </MDBBtn>
                                    )}
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                )}
            </MDBRow>
            {!loading && job && (
                <MDBRow className="mt-4">
                    <MDBBtn
                        outline
                        color="primary"
                        onClick={navigateJobSearch}
                        style={{ width: 200 }}
                    >
                        <MDBIcon className="me-2" icon="arrow-left" />
                        Back to Job Search
                    </MDBBtn>
                </MDBRow>
            )}
        </MDBContainer>
    );
};

export default JobDetailsPage;
