import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
    ApplyRequestModel,
    BuildProfileOptionsResponseModel,
    BuildProfileRequestModel,
    ContactUsRequestModel,
    JobFiltersResponseModel,
    JobLocationsRequestModel,
    JobLocationsResponseModel,
    JobResponseModel,
    JobsRequestModel,
    JobsResponseModel,
    MarkInterestedRequestModel,
    RequestStaffRequestModel,
    UploadDocumentsRequestModel,
    UploadDocumentsResponseModel,
} from './ApiContracts';

class ApiService {
    private client: AxiosInstance;

    constructor() {
        const apiUrl = process.env.REACT_APP_API_URL;
        const apiKey = process.env.REACT_APP_API_KEY;
        this.client = axios.create({
            baseURL: apiUrl,
            headers: {
                'Content-Type': 'application/json',
                'XS-Context': 'web-landingpage',
                'XS-api-key': apiKey,
            },
        });

        // Intercepting the responses to handle errors globally.
        this.client.interceptors.response.use(
            (response) => response,
            (error) => {
                console.error(
                    'API call error',
                    error.response || error.message,
                );
                return Promise.reject(error.response || error.message);
            },
        );

        // Add request interceptor to include twclid and source in headers if present
        this.client.interceptors.request.use(
            (config) => {
                const twclid = localStorage.getItem('twclid');
                if (twclid) {
                    config.headers['XS-twclid'] = twclid;
                }

                const source = localStorage.getItem('source');
                if (source) {
                    config.headers['XS-source'] = source;
                } else {
                    const referrer = document.referrer;
                    if (referrer) {
                        config.headers['XS-source'] = referrer;
                    }
                }
                return config;
            },
            (error) => {
                // Handle the error
                return Promise.reject(error);
            },
        );
    }

    async uploadDocuments(
        data: UploadDocumentsRequestModel,
    ): Promise<UploadDocumentsResponseModel> {
        try {
            const response: AxiosResponse<UploadDocumentsResponseModel> =
                await this.client.put('/api/document/upload', data);
            return response.data;
        } catch (error) {
            console.error('Failed to upload documents:', error);
            throw error;
        }
    }

    async jobFilters(): Promise<JobFiltersResponseModel> {
        try {
            const response: AxiosResponse<JobFiltersResponseModel> =
                await this.client.get('/api/jobs/filters');
            return response.data;
        } catch (error) {
            console.error('Failed to retrieve job filters', error);
            throw error;
        }
    }

    async listJobs(data: JobsRequestModel): Promise<JobsResponseModel> {
        try {
            const response: AxiosResponse<JobsResponseModel> =
                await this.client.post('/api/jobs/list', data);
            return response.data;
        } catch (error) {
            console.error('Failed to list jobs:', error);
            throw error;
        }
    }

    async getJobById(jobId: number): Promise<JobResponseModel> {
        try {
            const response: AxiosResponse<JobResponseModel> =
                await this.client.get(`/api/jobs/job/${jobId}`);
            return response.data;
        } catch (error) {
            console.error(`Failed to get job with ID ${jobId}:`, error);
            throw error;
        }
    }

    async jobLocations(
        data: JobLocationsRequestModel,
    ): Promise<JobLocationsResponseModel> {
        try {
            const response: AxiosResponse<JobLocationsResponseModel> =
                await this.client.post('/api/jobs/locations', data);
            return response.data;
        } catch (error) {
            console.error('Failed to retrieve job locations', error);
            throw error;
        }
    }

    async submitContact(data: ContactUsRequestModel): Promise<void> {
        try {
            await this.client.put('/api/submissions/contact', data);
        } catch (error) {
            console.error('Failed to submit contact:', error);
            throw error;
        }
    }

    async requestStaff(data: RequestStaffRequestModel): Promise<void> {
        try {
            await this.client.put('/api/submissions/requestStaff', data);
        } catch (error) {
            console.error('Failed to request staff:', error);
            throw error;
        }
    }

    async markInterested(data: MarkInterestedRequestModel): Promise<void> {
        try {
            await this.client.put('/api/submissions/mark-interested', data);
        } catch (error) {
            console.error(
                'Failed to mark interested in job: ' + data.jobId,
                error,
            );
            throw error;
        }
    }

    async applyForJob(data: ApplyRequestModel): Promise<void> {
        try {
            await this.client.put('/api/submissions/apply', data);
        } catch (error) {
            console.error('Failed to apply for job: ' + data.jobId, error);
            throw error;
        }
    }

    async buildProfile(data: BuildProfileRequestModel): Promise<void> {
        try {
            await this.client.put('/api/submissions/build-profile', data);
        } catch (error) {
            console.error('Failed to build profile', error);
            throw error;
        }
    }

    async getBuildProfileOptions(): Promise<BuildProfileOptionsResponseModel> {
        try {
            const response: AxiosResponse<BuildProfileOptionsResponseModel> =
                await this.client.get('/api/submissions/options/build-profile');
            return response.data;
        } catch (error) {
            console.error('Failed to retrieve build profile options', error);
            throw error;
        }
    }
}

export default ApiService;
