import React, { useState, useEffect } from 'react';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBInput,
    MDBSpinner,
} from 'mdb-react-ui-kit';
import ApiService from 'services/ApiService';
import { MarkInterestedRequestModel } from 'services/ApiContracts';
import logo from 'assets/logo50.png';

interface MarkInterestedDialogProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    jobId: number;
    onSuccess?: (submitted: boolean) => void; // Added callback prop
}

const apiService = new ApiService();

const MarkInterestedDialog: React.FC<MarkInterestedDialogProps> = ({
    showModal,
    setShowModal,
    jobId,
    onSuccess,
}) => {
    // State variables
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [formData, setFormData] = useState<{
        name: string;
        email: string;
        phone: string;
    }>({
        name: '',
        email: '',
        phone: '',
    });

    // On modal open, load data from session storage
    useEffect(() => {
        if (showModal) {
            const storedName = sessionStorage.getItem('markInterestedName');
            const storedEmail = sessionStorage.getItem('markInterestedEmail');
            const storedPhone = sessionStorage.getItem('markInterestedPhone');
            setFormData({
                name: storedName || '',
                email: storedEmail || '',
                phone: storedPhone || '',
            });
            setFormError(null);
            setFormSuccess(false);
        }
    }, [showModal]);

    const closeModal = () => {
        setShowModal(false);
    };

    const handleFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormError(null);
        setFormSuccess(false);
        setIsSubmitting(true);

        try {
            // Validate required fields
            if (!formData.name || !formData.email || !formData.phone) {
                setFormError('Please fill out all required fields.');
                setIsSubmitting(false);
                return;
            }

            const markInterestedRequest: MarkInterestedRequestModel = {
                ...formData,
                jobId,
            };

            await apiService.markInterested(markInterestedRequest);
            setFormSuccess(true);

            // Save data to session storage
            sessionStorage.setItem('markInterestedName', formData.name);
            sessionStorage.setItem('markInterestedEmail', formData.email);
            sessionStorage.setItem('markInterestedPhone', formData.phone);

            // Store job ID in session storage
            const storedJobIds = sessionStorage.getItem(
                'markedInterestedJobIds',
            );
            let jobIds: number[] = storedJobIds ? JSON.parse(storedJobIds) : [];

            // Add current jobId if not already in the list
            if (!jobIds.includes(jobId)) {
                jobIds.push(jobId);
                sessionStorage.setItem(
                    'markedInterestedJobIds',
                    JSON.stringify(jobIds),
                );
            }

            // Call the onSuccess callback if provided
            if (onSuccess) {
                onSuccess(true);
            }
        } catch (error) {
            setFormError(
                "Something went wrong. Please try again, and if the problem persists, contact us at <a href='mailto:info@x-staffing.com'>info@x-staffing.com</a>.",
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <MDBModal open={showModal} setOpen={setShowModal} tabIndex="-1">
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader className="modal-header d-flex align-items-center">
                        <img src={logo} alt="Logo" className="me-3" />
                        <div className="flex-grow-1">
                            <MDBModalTitle>
                                Let Us Know You're Interested
                            </MDBModalTitle>
                            <em>
                                Drop us your contact info, and we'll reach out
                                with more details about this role!
                            </em>
                        </div>
                        <MDBBtn
                            className="btn-close btn-close-white"
                            color="none"
                            onClick={closeModal}
                        ></MDBBtn>
                    </MDBModalHeader>

                    <MDBModalBody className="modal-body">
                        {!formSuccess ? (
                            <form onSubmit={handleFormSubmit}>
                                <MDBInput
                                    wrapperClass="mb-3"
                                    id="name"
                                    label="Name *"
                                    value={formData.name}
                                    onChange={handleFormInputChange}
                                    required
                                />
                                <MDBInput
                                    wrapperClass="mb-3"
                                    type="email"
                                    id="email"
                                    label="Email *"
                                    value={formData.email}
                                    onChange={handleFormInputChange}
                                    required
                                />
                                <MDBInput
                                    wrapperClass="mb-3"
                                    type="tel"
                                    id="phone"
                                    label="Phone *"
                                    value={formData.phone}
                                    onChange={handleFormInputChange}
                                    required
                                />

                                {formError && (
                                    <div
                                        className="submit-error-message mb-3"
                                        dangerouslySetInnerHTML={{
                                            __html: formError,
                                        }}
                                    />
                                )}

                                <MDBModalFooter>
                                    <MDBBtn
                                        color="secondary"
                                        type="button"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </MDBBtn>
                                    <MDBBtn
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="submit-button"
                                    >
                                        {isSubmitting ? (
                                            <MDBSpinner
                                                size="sm"
                                                role="status"
                                                tag="span"
                                            />
                                        ) : (
                                            'Submit'
                                        )}
                                    </MDBBtn>
                                </MDBModalFooter>
                            </form>
                        ) : (
                            <div className="success-message text-center">
                                <h5>Thank you for your interest!</h5>
                                <p>
                                    We have received your information and will
                                    be in touch soon.
                                </p>
                                <MDBModalFooter>
                                    <MDBBtn
                                        color="primary"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </MDBBtn>
                                </MDBModalFooter>
                            </div>
                        )}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
};

export default MarkInterestedDialog;
